import axios from "axios";

// export const api = axios.create({
//   baseURL: 'https://hmg.bbapi.byebnk.com',
// })

// export const documentoRejeitar = '5a11817a-4dd6-4aad-9b2d-fc7012479d08';

export const api = axios.create({
  baseURL: 'https://bbapi.byebnk.com',
})

export const documentoRejeitar = '5d3723b1-3ef7-4530-9284-54ab59c96b44';
