import { FormEvent, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import { Form, InputWrapper } from "./styles";
import logo from "../../assets/img/logo.png";
import { api } from "../../services/api";

export function LoginForm() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const history = useHistory();
  const { signIn } = useAuth();

  async function handleSubmit(event: FormEvent) {
    event.preventDefault();

    try {
      await signIn(username, password);
      history.push("/documentos");
    } catch (error) {
      console.log(error);
    }
  }

  async function resetPassword() {
    await api.post("/v1/auth/esqueci-senha/", {
      username: username
    })
    .then(function(response) {
      console.log(response);
      history.push("/esqueci-senha");
    })
    .catch(function(error) {
      console.log(error.response.data.erros);
      console.log(error.response)
      console.log(error.request)

      if(error.response.data.erros[0] === 'detail: Not found.'){
        toast.error('Usuário incorreto.')
      } else if (error.response.data.erros[0] === 'detail: Não encontrado.'){
        toast.error('Usuário incorreto.')
      } else {
        toast.error('Aconteceu um erro inesperado.')
      }
    })
  }

  return (
    <>
      <Toaster />
      <Form action="" onSubmit={handleSubmit}>
        <img src={logo} alt="" />
        <p style={{ color: "white" }}>
          Utilize o seu CPF/CNPJ (sem a pontuação) e senha do aplicativo byebnk.
        </p>
        <InputWrapper>
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            name="username"
            required
          />
          <label htmlFor="name" className={username.length > 0 ? "active" : ""}>
            CPF/CNPJ
          </label>
        </InputWrapper>

        <InputWrapper>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            name="password"
            required
          />
          <label
            htmlFor="password"
            className={password.length > 0 ? "active" : ""}
          >
            Senha
          </label>
        </InputWrapper>

        <a
          href="#"
          style={{alignSelf: "self-end", paddingTop: "1rem"}}
          onClick={resetPassword}
        >
          Esqueci a minha senha
        </a>

        <button type="submit">Entrar</button>
      </Form>
    </>
  );
}
