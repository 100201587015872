import { createContext, ReactNode, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import toast from "react-hot-toast";
import { api } from "../services/api";

interface User {
  user: string;
  token: string;
  isAuthenticated: boolean;
  logOut: () => void;
  signIn: (username: string, password: string) => Promise<void>;
}

export const AuthContext = createContext({} as User);

export function AuthContextProvider({ children }: { children: ReactNode }) {
  const [user, setUser] = useState("");
  const [token, setToken] = useState("");
  const [cookies, setCookie, removeCookie] = useCookies(["byebnk@token"]);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(() => {
    const tokenString = cookies["byebnk@token"];

    if (!tokenString) {
      return false;
    } else {
      setToken(tokenString || "");
      return true;
    }
  });

  useEffect(() => {
    const tokenString = cookies["byebnk@token"];

    if (!tokenString) {
      setIsAuthenticated(false);
    } else {
      setToken(tokenString || "");
      setIsAuthenticated(true);
    }
  }, [cookies]);

  function logOut() {
    removeCookie("byebnk@token");
    setUser("");
    setToken("");
    setIsAuthenticated(false);
  }

  async function signIn(username: string, password: string) {
    await api.post("/v1/usuarios/token/obter/", {
      username: username,
      password: password
    })
    .then(function (response) {
      console.log(response);
      const { token }: any = response.data
      setCookie("byebnk@token", token);
      setToken(token);
      setUser(username);
      setIsAuthenticated(true);
    })
    .catch(function (error) {
      setIsAuthenticated(false);
      try {
        console.log(error.response.data.erros)
        console.log(error.response.status)
        console.log(error.response)
        console.log(error.request)

        if(error.response.status === 401){
          toast.error('Usuário ou senha inválida.')
        } else if (error.response.status === 429){
          toast.error('A conta desse usuário está bloqueada.')
        } else {
          toast.error('Aconteceu um erro inesperado.')
        }
      } catch (error) {
        toast.error('Aconteceu um erro inesperado.')
      }
    });
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        token,
        isAuthenticated,
        logOut,
        signIn,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
