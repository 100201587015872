import { ResetPasswordForm } from "../components/ResetPasswordForm";
import { Main } from "../styles/auth";

export function ResetPassword() {
  
  return (
    <Main>
      <ResetPasswordForm />
    </Main>
  );
}
