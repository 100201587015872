import { FormEvent, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useHistory } from "react-router-dom";
import { Form, InputWrapper } from "./styles";
import logo from "../../assets/img/logo.png";
import { api } from "../../services/api";

export function ResetPasswordForm() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [codigo, setCodigo] = useState("");
  const history = useHistory();

  async function handleSubmit(event: FormEvent) {
    event.preventDefault();

    await api.post("/v1/auth/esqueci-senha/resetar/", {
      username: username,
      codigo: codigo,
      senha_nova: password,
    })
    .then(function(response) {
      console.log(response);
      console.log("senha resetada com sucesso ...")
      toast.success('Senha alterada com sucesso')
      history.push("/")
    })
    .catch(function(error) {
      console.log(error.response.data.erros);
      console.log(error.response);
      console.log(error.request);

      if(error.response.data.erros[0] === "codigo: Inválido"){
        toast.error('CPF/CNPJ ou Código inválido.')
      } else if (error.response.data.erros[0] === "non_field_errors: Esta senha é muito curta. Ela precisa conter pelo menos 8 caracteres.") {
        toast.error('Esta senha é muito curta, sua senha precisa conter pelo menos 8 caracteres.')
      } else if (error.response.data.erros[0] === "non_field_errors: This password is too short. It must contain at least 8 characters.") {
        toast.error('Esta senha é muito curta, sua senha precisa conter pelo menos 8 caracteres.')
      } else if (error.response.data.erros[0] === "non_field_errors: Adicione outra palavra ou duas. Palavras incomuns são melhores.") {
        toast.error('Esta senha é muito fraca, adicione outra palavra ou duas, palavras incomuns são melhores.')
      } else if (error.response.data.erros[0] === "non_field_errors: Add another word or two. Uncommon words are better.") {
        toast.error('Esta senha é muito fraca, adicione outra palavra ou duas, palavras incomuns são melhores.')
      } else {
        toast.error('Aconteceu um erro inesperado.')
      }
    })
  }

  return (
    <>
      <Toaster />
      <Form action="" onSubmit={handleSubmit}>
        <img src={logo} alt="" />
        <p style={{ color: "white" }}>
          Confirme seu CPF/CNPJ (sem a pontuação), o código de segurança enviado para o seu email e a nova senha.
        </p>
        <InputWrapper>
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            name="username"
            required
          />
          <label htmlFor="name" className={username.length > 0 ? "active" : ""}>
            CPF/CNPJ
          </label>
        </InputWrapper>

        <InputWrapper>
          <input
            type="text"
            value={codigo}
            onChange={(e) => setCodigo(e.target.value)}
            name="codigo"
            required
          />
          <label htmlFor="codigo" className={codigo.length > 0 ? "active": ""}>
            Código de segurança
          </label>
        </InputWrapper>

        <InputWrapper>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            name="password"
            required
          />
          <label
            htmlFor="password"
            className={password.length > 0 ? "active" : ""}
          >
            Nova senha
          </label>
        </InputWrapper>

        <button type="submit">Alterar senha</button>
      </Form>
    </>
  );
}
